import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import axios from "axios";

import {
    GET_USER_DETAILS,
    UPDATE_PROFILE,
    GET_CRC_ACTIVITIES,
    POST_CRC_ACTIVITIES,
    PUT_CRC_ACTIVITIES,
    GET_CRC_ACTIVITY_DETAIL
} from "../actions";

import {
    getUserDetailsSuccess,
    getUserDetailsError,
    updateProfileSuccess,
    updateProfileError,
    getCRCActivitiesSuccess,
    getCRCActivitiesError,
    addCRCActivitiesSuccess,
    addCRCActivitiesError,
    updateCRCActivitiesSuccess,
    updateCRCActivitiesError,
    getCRCActivityDetailSuccess,
    getCRCActivityDetailError
} from "./action";

import {
    GetUserProfileDetailsAPI,
    UpdateProfileAPI,
    GetCRCActivitiesAPI,
    AddCRCActivitiesAPI,
    EditCRCActivitiesAPI,
    GetCRCActivityDetailAPI
} from "../../constants/apiRoutes";
import { setAlert } from "../Alerts/action";

const getUserProfileDetails = async () => {
    return await axios.get(GetUserProfileDetailsAPI);
};
const updateProfileAPI = async (data) => {
    return axios.post(UpdateProfileAPI, data);
};
const getCRCActivitiesAPI = async (data) => {
    return axios.get(`${GetCRCActivitiesAPI}/${data.userId}`);
};
const addCRCActivitiesAPI = async (data) => {
    return axios.post(AddCRCActivitiesAPI, data);
};
const editCRCActivitiesAPI = async (data) => {
    return axios.put(`${EditCRCActivitiesAPI}/${data.activityId}`, data);
};
const getCRCActivityDetailAPI = async (data) => {
    return axios.get(`${GetCRCActivityDetailAPI}/${data.activityId}`);
};

function* getUserProfileDetailSaga() {
    try {
        const { data } = yield call(getUserProfileDetails);
        yield put(getUserDetailsSuccess(data));
    } catch (error) {
        yield put(getUserDetailsError(error));
        yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    }
}
function* updateProfileSaga({ payload }) {
    try {
        const { data } = yield call(updateProfileAPI, payload );
        yield put(updateProfileSuccess(data.data));
        yield put(setAlert({ message: "Profile Updated.", type: "SUCCESS" }));
    } catch (error) {
        yield put(updateProfileError(error));
        yield put(setAlert({ message: "Error Updating Profile.", type: "ERROR" }));
    }
}
function* getCRCActivitiesSaga({ payload }) {
    try {
        const { data } = yield call(getCRCActivitiesAPI, payload);
        yield put(getCRCActivitiesSuccess(data));
    } catch (error) {
        yield put(getCRCActivitiesError(error));
    }
}
function* addCRCActivitiesSaga({ payload }) {
    try {
        const { data } = yield call(addCRCActivitiesAPI, payload );
        yield put(addCRCActivitiesSuccess(data.data));
        yield put(setAlert({ message: "New Activity added.", type: "SUCCESS" }));
    } catch (error) {
        yield put(addCRCActivitiesError(error));
        yield put(setAlert({ message: "Error adding activity.", type: "ERROR" }));
    }
}
function* updateCRCActivitiesSaga({ payload }) {
    try {
        const { data } = yield call(editCRCActivitiesAPI, payload );
        yield put(updateCRCActivitiesSuccess(data.data));
        // yield put(setAlert({ message: "Saved.", type: "SUCCESS" }));
    } catch (error) {
        yield put(updateCRCActivitiesError(error));
        yield put(setAlert({ message: "Error updating activity.", type: "ERROR" }));
    }
}
function* getCRCActivityDetailSaga({ payload }) {
    try {
        const { data } = yield call(getCRCActivityDetailAPI, payload);
        yield put(getCRCActivityDetailSuccess(data));
    } catch (error) {
        yield put(getCRCActivityDetailError(error));
    }
}

export function* watchGetUserProfileDetail() {
    yield takeEvery(GET_USER_DETAILS, getUserProfileDetailSaga);
}
export function* watchUpdateProfile() {
    yield takeEvery(UPDATE_PROFILE, updateProfileSaga);
}
export function* watchGetCRCActivitiesSaga() {
    yield takeEvery(GET_CRC_ACTIVITIES, getCRCActivitiesSaga);
}
export function* watchAddCRCActivitiesSaga() {
    yield takeEvery(POST_CRC_ACTIVITIES, addCRCActivitiesSaga);
}
export function* watchUpdateCRCActivitiesSaga() {
    yield takeEvery(PUT_CRC_ACTIVITIES, updateCRCActivitiesSaga);
}
export function* watchGetCRCActivityDetailSaga() {
    yield takeEvery(GET_CRC_ACTIVITY_DETAIL, getCRCActivityDetailSaga);
}


export default function* rootSaga() {
    yield all([
        fork(watchGetUserProfileDetail),
        fork(watchUpdateProfile),
        fork(watchGetCRCActivitiesSaga),
        fork(watchAddCRCActivitiesSaga),
        fork(watchUpdateCRCActivitiesSaga),
        fork(watchGetCRCActivityDetailSaga),
    ]);
}
