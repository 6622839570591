const baseUrl = "http://localhost:8000";

// Auth
export const LoginUserAPI = `${baseUrl}/users/authenticate`; // POST { username, password }
export const RegisterUserAPI = `${baseUrl}/users/signup`; // POST { username, password, role }
export const UpdateProfileAPI = `${baseUrl}/users/updateprofile`; // POST

// User Details
export const GetUserProfileDetailsAPI = `${baseUrl}/users/me`; // POST { games, period }

// Activities
export const GetCRCActivitiesAPI = `${baseUrl}/activity/get`; // GET { Add /:userId }
export const AddCRCActivitiesAPI = `${baseUrl}/activity/new`; // POST
export const EditCRCActivitiesAPI = `${baseUrl}/activity/update`; // PUT { Add /activityId }
export const GetCRCActivityDetailAPI = `${baseUrl}/activity/getActivity`; // GET { Add /:activityId }


export { baseUrl };