import React, { useContext, createContext, useRef, useState } from "react";
import { connect } from "react-redux";

import { setAlert, removeAlert } from "../redux/Alerts/action";
import Alerts from "../components/Alerts";

import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

const AlertConfirmationContext = createContext(Promise.reject);
export const useAlertConfirmation = () => useContext(AlertConfirmationContext);

const AlertCofirmationService = ({ children, setAlert, removeAlert }) => {
  const awaitingPromiseRef = useRef();

  const [alertOptions, setAlertOptions] = useState(null);

  const createConditionalAlert = opt => {
    setAlertOptions({ ...opt, isFallBackAlert: true });
    setAlert({
      message: opt.message || "Are you sure",
      type: opt.type || "WARNING",
    });
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleSuccess = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    removeAlert();
    setAlertOptions(null);
  };

  const handleCancel = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    removeAlert();
    setAlertOptions(null);
  };

  const options = {
    timeout: 5000,
    position: positions.BOTTOM_RIGHT,
    offset: '1rem',
  };

  return (
    <Provider template={AlertTemplate} {...options}>
      <AlertConfirmationContext.Provider value={createConditionalAlert} children={children} />
      <Alerts
        successCallBack={handleSuccess}
        cancelCallBack={handleCancel}
        successText={alertOptions ? alertOptions.successText : "Yes"}
        cancelText={alertOptions ? alertOptions.cancelText : "No"}
        isFallBackAlert={alertOptions ? alertOptions.isFallBackAlert : false}
      />
    </Provider>
  );
};

export default connect(null, { setAlert, removeAlert })(AlertCofirmationService);
