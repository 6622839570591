import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import Breadcrumbs from '../../../../../components/Common/Breadcrumb';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import EditableLabel from './EditableLabel';
import { getCRCActivityDetail, addCRCActivities, updateCRCActivities } from '../../../../../redux/Main/action';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const TaskDetails = ({ getCRCActivityDetail, addCRCActivities, activityDetail, updateCRCActivities }) => {
    const breadcrumbItems = [
        { title: "Home", link: "/dashboard" },
        { title: "Dashboard", link: "#" }
    ];

    const history = useHistory();
    const { userId, taskId } = useParams();

    const [timelineData, setTimelineData] = useState([]);

    const [activityData, setActivityData] = useState({
        userId: userId,
        activityName: "",
        activityLocation: "",
        todo: timelineData
    });

    const getData = async () => {
        await getCRCActivityDetail({ activityId: taskId });
    };
    const updateData = async (todo=timelineData) => {
        await updateCRCActivities({ activityId: taskId, todo: todo });
    };



    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (taskId) {
            setActivityData(activityDetail);
            setTimelineData(activityDetail?.todo);
        }
        // eslint-disable-next-line
    }, [activityDetail]);


    const handleChange = e => {
        const name = e.target.name;
        const val = e.target.value;
        setActivityData({ ...activityData, [name]: val });
    };

    const handleClickCheckbox = async (current) => {
        if (taskId) {
            const copy = timelineData;
            const now = new Date();
            const newOfCopy = copy.filter((item) => item.key !== current.key);
            setTimelineData([...newOfCopy, { ...current, status: !current.status, completedOn: !current.status ? now : ""  }])
            updateData([...newOfCopy, { ...current, status: !current.status, completedOn: !current.status ? now : "" }])
        }
    };
    const handleChangeActivity = async(currentRow, newVal) => {
        if (taskId) {
            const copy = timelineData;
            const newOfCopy = copy.filter((item) => item.key !== currentRow.key);
            setTimelineData([...newOfCopy, { ...currentRow, content: newVal }])
            updateData([...newOfCopy, { ...currentRow, content: newVal }])
        }
    }

    const addActivity = async () => {
        const newId = uuidv4()
        setTimelineData([...timelineData, { key: newId, content: 'New Activity Task', status: false, createdOn: new Date() }])
        updateData([...timelineData, { key: newId, content: 'New Activity Task', status: false, createdOn: new Date() }])
    };

    const handleAddNewActivity = async () => {
        await addCRCActivities({ ...activityData, todo: timelineData });
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title={taskId && activityData.activityName? `Details for Activity ${activityData.activityName}` : "Add New Activity"}
                    breadcrumbItems={breadcrumbItems}
                    showBtn={true}
                    handleClick={() => {
                        history.push(`/dashboard/crc/${userId}`);
                    }}
                />

                <Row>
                    <Col lg={8}>
                        <Row>
                            <Card style={{ width: "100%" }}>
                                <CardBody>
                                    <Form>
                                        <FormGroup row>
                                            <Label htmlFor="activity-name" className="col-md-2 col-form-label">Activity Name</Label>
                                            <Col md={10}>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    disabled={taskId}
                                                    name="activityName"
                                                    defaultValue={activityData.activityName}
                                                    onChange={handleChange}
                                                    // defaultValue="Artisanal kale"
                                                    id="activity-name"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="activity-location" className="col-md-2 col-form-label">Activity Location</Label>
                                            <Col md={10}>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    disabled={taskId}
                                                    name="activityLocation"
                                                    defaultValue={activityData.activityLocation}
                                                    onChange={handleChange}
                                                    // defaultValue="Artisanal kale"
                                                    id="activity-location"
                                                />
                                            </Col>
                                        </FormGroup>
                                        {!taskId && (
                                            <FormGroup row style={{ textAlign: "right" }}>
                                                <Col md={12}>
                                                    <Button
                                                        color="primary"
                                                        className=" waves-effect waves-light mr-1"
                                                        onClick={handleAddNewActivity}
                                                    >
                                                        Save
                                                    </Button>
                                                </Col>
                                            </FormGroup>
                                        )}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Row>
                        <Row style={{ gap: "1rem", alignItems: "center" }}>
                            <h4>Activity Tasks</h4>
                            <Button color="primary" size="sm" className="waves-effect waves-light mr-1" onClick={addActivity}
                                style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.2rem" }}
                            >
                                Add{"  "}
                                <i className="ri-add-line auti-custom-input-icon" />
                            </Button>
                        </Row>
                        {timelineData && timelineData.length > 0 && (
                            <VerticalTimeline layout="1-column-left" lineColor='#000'>
                                {/* {timelineData.sort((a, b) => a.status - b.status).map(item => ( */}
                                {timelineData.sort((a, b) => a.key - b.key).map(item => (
                                    <VerticalTimelineElement
                                        key={item.key}
                                        iconStyle={{ backgroundColor: "#fff" }}
                                        contentStyle={{ opacity: item.status && "0.5" }}
                                        icon={item.status && <CorrectIcon />}
                                        iconOnClick={() => handleClickCheckbox(item)}
                                        date={item.status && `Completed on ${moment(item.completedOn).format('MMMM Do YYYY, h:mm a')}`}
                                        dateClassName="p-0"
                                    >
                                        <EditableLabel
                                            itemRow={item}
                                            handleChangeActivity={handleChangeActivity}
                                        />
                                    </VerticalTimelineElement>
                                ))}
                            </VerticalTimeline>
                        )}
                    </Col>

                    {taskId && (
                        <Col lg={4}>
                            <Button color='primary' >Mark Attendance</Button>
                        </Col>
                    )}
                </Row>


            </Container>
        </div>
    )
}

const mapStateToProps = ({ main }) => ({
    activityDetail: main.activityDetail,
});

export default connect(mapStateToProps, { getCRCActivityDetail, addCRCActivities, updateCRCActivities })(TaskDetails);

const CorrectIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="240px" height="240px"><linearGradient id="IMoH7gpu5un5Dx2vID39Ra" x1="9.858" x2="38.142" y1="9.858" y2="38.142" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#9dffce" /><stop offset="1" stop-color="#50d18d" /></linearGradient><path fill="url(#IMoH7gpu5un5Dx2vID39Ra)" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z" /><linearGradient id="IMoH7gpu5un5Dx2vID39Rb" x1="13" x2="36" y1="24.793" y2="24.793" gradientUnits="userSpaceOnUse"><stop offset=".824" stop-color="#135d36" /><stop offset=".931" stop-color="#125933" /><stop offset="1" stop-color="#11522f" /></linearGradient><path fill="url(#IMoH7gpu5un5Dx2vID39Rb)" d="M21.293,32.707l-8-8c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414 c0.391-0.391,1.024-0.391,1.414,0L22,27.758l10.879-10.879c0.391-0.391,1.024-0.391,1.414,0l1.414,1.414 c0.391,0.391,0.391,1.024,0,1.414l-13,13C22.317,33.098,21.683,33.098,21.293,32.707z" /></svg>
);
