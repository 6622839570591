// USER REGISTER AUTH
export const USER_REGISTER = "USER_REGISTER";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";

// USER LOGIN AUTH
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";

// USER LOGOUT AUTH
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_ERROR = "USER_LOGOUT_ERROR";

// UPDATE PROFILE
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";

// ALERTS
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// GET USER DETAILS
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_ERROR = "GET_USER_DETAILS_ERROR";

// GET CRC ACTIVITIES
export const GET_CRC_ACTIVITIES = "GET_CRC_ACTIVITIES";
export const GET_CRC_ACTIVITIES_SUCCESS = "GET_CRC_ACTIVITIES_SUCCESS";
export const GET_CRC_ACTIVITIES_ERROR = "GET_CRC_ACTIVITIES_ERROR";

// POST CRC ACTIVITIES
export const POST_CRC_ACTIVITIES = "POST_CRC_ACTIVITIES";
export const POST_CRC_ACTIVITIES_SUCCESS = "POST_CRC_ACTIVITIES_SUCCESS";
export const POST_CRC_ACTIVITIES_ERROR = "POST_CRC_ACTIVITIES_ERROR";

// PUT CRC ACTIVITIES
export const PUT_CRC_ACTIVITIES = "PUT_CRC_ACTIVITIES";
export const PUT_CRC_ACTIVITIES_SUCCESS = "PUT_CRC_ACTIVITIES_SUCCESS";
export const PUT_CRC_ACTIVITIES_ERROR = "PUT_CRC_ACTIVITIES_ERROR";

// GET CRC ACTIVITY DETAIL
export const GET_CRC_ACTIVITY_DETAIL = "GET_CRC_ACTIVITY_DETAIL";
export const GET_CRC_ACTIVITY_DETAIL_SUCCESS = "GET_CRC_ACTIVITY_DETAIL_SUCCESS";
export const GET_CRC_ACTIVITY_DETAIL_ERROR = "GET_CRC_ACTIVITY_DETAIL_ERROR";
