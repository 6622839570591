import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  USER_LOGOUT,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_ERROR,
  USER_REGISTER,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_ERROR,
} from "../actions";

export const registerUser = (payload, history) => ({
  type: USER_REGISTER,
  payload,
  history
});

export const registerUserSuccess = payload => ({
  type: USER_REGISTER_SUCCESS,
  payload,
});

export const registerUserError = payload => ({
  type: USER_REGISTER_ERROR,
  payload,
});

export const userLogin = (payload, history) => ({
  type: USER_LOGIN,
  payload,
  history
});

export const userLoginSuccess = payload => ({
  type: USER_LOGIN_SUCCESS,
  payload,
});

export const userLoginError = payload => ({
  type: USER_LOGIN_ERROR,
  payload,
});

export const userLogout = (payload, history) => ({
  type: USER_LOGOUT,
  payload,
  history
});

export const userLogoutSuccess = () => ({
  type: USER_LOGOUT_SUCCESS
});

export const userLogoutError = () => ({
  type: USER_LOGOUT_ERROR
});

