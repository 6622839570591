import React, { useState } from "react";

const EditableLabel = ({ itemRow, handleChangeActivity }) => {

  const editable = itemRow.status || true;
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(itemRow.content);

  const handleLabelClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  
  const handleInputBlur = () => {
    setIsEditing(false);
    handleChangeActivity(itemRow, inputValue)
  };

  return (
    <div className="editable-label">
      {editable && isEditing ? (
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
        />
      ) : (
        <label onClick={handleLabelClick} className="todo-label">{inputValue}</label>
      )}
    </div>
  );
};

export default EditableLabel;