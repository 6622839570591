import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Row
} from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { getUserDetails } from "../../../../redux/Main/action";
import { connect } from "react-redux";
import DummyUserPic from "../../../../assets/images/dummyUser.jpg";
import ProfileForm from "./Form";

const Profile = ({ getUserDetails, userDetails }) => {
  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Profile", link: "#" }
  ];

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line
  }, [])

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Profile" breadcrumbItems={breadcrumbItems} />
        <Row>
          <Col mg={6} xl={3}>
            <Card style={{ padding: "1.5rem" }} className="text-center">
              <CardImg
                top
                className="img-fluid rounded-circle"
                src={userDetails.photo || DummyUserPic}
                alt="Skote"
              />
              {/* <i className="ri-pencil-line auti-custom-input-icon" /> */}
              <CardBody>
                <CardTitle className="mt-0">{`${userDetails.name} `}</CardTitle>
                <CardText>{userDetails.email}</CardText>
              </CardBody>
            </Card>
          </Col>

          <Col mg={6} xl={9}>
            <Card>
              <CardBody>
                
                <ProfileForm userDetails={userDetails} />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ main }) => ({
  userDetails: main.userDetails
})

export default connect(mapStateToProps, {getUserDetails})(Profile);
