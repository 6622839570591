import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardBody, CardText, CardTitle, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../../../components/Common/Breadcrumb";
import { Link } from 'react-router-dom';
import { getCRCActivities } from "../../../../../redux/Main/action";

const CRCProfile = ({ getCRCActivities, crcActivities }) => {
  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Dashboard", link: "#" }
  ];

  const history = useHistory();
  const { userId } = useParams();

  const getAllCRC = async () => {
    await getCRCActivities({ userId });
  }

  useEffect(() => {
    getAllCRC();
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={`${userId}`}
            breadcrumbItems={breadcrumbItems}
            showBtn={true}
            handleClick={() => {
              history.push(`/dashboard/crc`);
            }}
          />

          <Row>
            <h4>Activities Assigned</h4>
          </Row>
          <Col md={6}>
            {/* <Tasks /> */}
          </Col>
          <Row>

            {crcActivities && crcActivities.map((item) => (
              <Col lg={3} key={item._id}>
                <CurrentTasks data={item} userId={userId} />
              </Col>
            ))}


            <Col lg={3}>
              <Link to={`/dashboard/crc/${userId}/add`}>
                <Card style={{ backgroundColor: "transparent", border: "2px dashed #000", height: "88%", boxShadow: "rgba(0, 0, 0, 0.25) 0px 5px 15px" }} >
                  <CardBody style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", cursor: "pointer" }}>
                    <h3>Add New Activity</h3>
                  </CardBody>
                </Card>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ main }) => ({
  crcActivities: main.crcActivities,
});

export default connect(mapStateToProps, { getCRCActivities })(CRCProfile);

const CurrentTasks = ({ data, userId }) => {
  return (
    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.25) 0px 5px 15px" }} >
      <CardBody>
        <CardTitle className="mt-0 font-weight-bold">{data.activityName}</CardTitle>
        <CardText className='mb-0'>
          <Row>
            <Col lg={1} md={1} sm={1}>
              <i className="ri-map-pin-fill auti-custom-input-icon" />
            </Col>
            <Col>
              <p className='mb-1'>{data.activityLocation}</p>
            </Col>
          </Row>
          <Row style={{ color: "rgb(28,162,28)" }}>
            <Col lg={1} md={1} sm={1}>
              <i className="ri-task-line auti-custom-input-icon" style={{ fontWeight: "600" }} />
            </Col>
            <Col>
              <p className='mb-0'><i>{data.todo.filter(item => item.status === true).length} completed tasks</i></p>
            </Col>
          </Row>
          <Row style={{ color: "rgb(162, 28, 28)" }}>
            <Col lg={1} md={1} sm={1}>
              <i className="ri-file-warning-line auti-custom-input-icon" style={{ fontWeight: "600" }} />
            </Col>
            <Col>
              <p className='mb-0'><i>{data.todo.filter(item => item.status === false).length} pending tasks</i></p>
            </Col>
          </Row>
        </CardText>
        <Row>
          <Col lg={12} md={12} style={{ textAlign: "right" }}>
            <Link to={`/dashboard/crc/${userId}/view/${data._id}`} className="btn-sm btn-primary waves-effect waves-light">View</Link>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}