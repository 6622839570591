import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { setAlert } from "../../../../redux/Alerts/action";
import { updateProfile } from "../../../../redux/Main/action";


const ProfileForm = ({ updateProfile, userDetails }) => {

  const [formData, setFormData] = useState({
    email: "",
    name: "",
    phone: "",
    address: ""
  });
  const history = useHistory();
  const handleSubmit = async () => {
    try {
      if (!formData.email) {
        setAlert({ message: "Email feild cannot be empty", type: "ERROR" });
        throw new Error("Email feild cannot be empty");
      }
      if (!formData.name) {
        setAlert({ message: "Name feild cannot be empty", type: "ERROR" });
        throw new Error("Name feild cannot be empty");
      }
      if (!formData.phone) {
        setAlert({ message: "Phone feild cannot be empty", type: "ERROR" });
        throw new Error("Phone feild cannot be empty");
      }
      if (!formData.address) {
        setAlert({ message: "Address feild cannot be empty", type: "ERROR" });
        throw new Error("Address feild cannot be empty.");
      }
      await updateProfile(formData);
      history.push("/dashboard");
    } catch (err) { console.log(err); }
  }

  const handleChange = e => {
    const name = e.target.name;
    const val = e.target.value;
    setFormData({ ...formData, [name]: val });
  };

  useEffect(() => {
    setFormData(userDetails);
    // eslint-disable-next-line
  }, [userDetails])

  return (
    <Form>
      <FormGroup row>
        <Label htmlFor="email" className="col-md-2 col-form-label">Email</Label>
        <Col md={10}><Input className="form-control" value={formData.email || ""} onChange={(e) => handleChange(e)} name="email" type="text" id="email" /></Col>
      </FormGroup>
      <FormGroup row>
        <Label htmlFor="full-name" className="col-md-2 col-form-label">Full Name</Label>
        <Col md={10}><Input className="form-control" value={formData.name || ""} onChange={(e) => handleChange(e)} name="name" type="text" id="full-name" /></Col>
      </FormGroup>
      <FormGroup row>
        <Label htmlFor="phone" className="col-md-2 col-form-label">Phone</Label>
        <Col md={10}><Input className="form-control" value={formData.phone || ""} onChange={(e) => handleChange(e)} name="phone" type="text" id="phone" /></Col>
      </FormGroup>
      <FormGroup row>
        <Label htmlFor="address" className="col-md-2 col-form-label">Address</Label>
        <Col md={10}><Input className="form-control" value={formData.address || ""} onChange={(e) => handleChange(e)} name="address" type="textarea" id="address" /></Col>
      </FormGroup>
      <FormGroup row style={{ justifyContent: "center" }}>
        <Button color="primary" onClick={() => handleSubmit()}>Save</Button>
      </FormGroup>
    </Form>
  );
};


export default connect(null, { updateProfile })(ProfileForm);

