import { combineReducers } from "redux";
import auth from "./auth/reducer";
import alert from "./Alerts/reducer";
import main from "./Main/reducer";

const reducers = combineReducers({
  auth,
  alert,
  main
});

export default reducers;
