
import CRC from "../Components/CRC";
import Dashboard from "../Components/Dashboard";
import DORC from "../Components/DORC";
import DPO from "../Components/DPO";

export const DashboardRoutes = [
  {
    Component: Dashboard,
    path: "/home",
    exact: true,
    id: "dashboardHome",
    routePath: "/dashboard/home",
    name: "Dashboard",
    icon: "ri-home-smile-line",
    visibleTo: ["Admin", "DPO", "DORC", "CRC"]
  },
  {
    Component: DPO,
    path: "/dpo",
    exact: true,
    id: "dashboardDPO",
    routePath: "/dashboard/dpo",
    name: "DPO",
    icon: "ri-user-fill",
    visibleTo: ["Admin"]
  },
  {
    Component: DORC,
    path: "/dorc",
    exact: true,
    id: "dashboardDURCC",
    routePath: "/dashboard/dorc",
    name: "DURCC",
    icon: "ri-user-2-fill",
    visibleTo: ["Admin", "DPO"]
  },
  {
    Component: CRC,
    path: "/crc",
    exact: true,
    id: "dashboardCRC",
    routePath: "/dashboard/crc",
    name: "CRC",
    icon: "ri-user-5-fill",
    visibleTo: ["Admin", "DPO", "DORC"]
  }
];
