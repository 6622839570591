import React from "react";
import { Card, CardBody, Col, Container, Media, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

const Dashboard = () => {
  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Dashboard", link: "#" }
  ];

  const cardsData = [
    {
      name: "Number of DPO",
      number: 13,
      icon: "ri-building-line"
    },
    {
      name: "Number of DORC",
      number: 169,
      icon: "ri-building-line"
    },
    {
      name: "Number of CRC",
      number: 272,
      icon: "ri-building-line"
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Dashboard"
            breadcrumbItems={breadcrumbItems}
            showBtn={false}
          />

          <Row>
            {cardsData.map((card, i) =>
              <Col md={4} key={i}>
                <Card>
                  <CardBody>
                    <Media>
                      <Media body className="overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          {card.name}
                        </p>
                        <h4 className="mb-0">
                          {card.number}
                        </h4>
                      </Media>
                      <div className="text-primary">
                        <i className={card.icon + " font-size-24"} />
                      </div>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
