import {
    GET_USER_DETAILS,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_ERROR,
    UPDATE_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_ERROR,
    GET_CRC_ACTIVITIES,
    GET_CRC_ACTIVITIES_SUCCESS,
    GET_CRC_ACTIVITIES_ERROR,
    POST_CRC_ACTIVITIES,
    POST_CRC_ACTIVITIES_SUCCESS,
    POST_CRC_ACTIVITIES_ERROR,
    PUT_CRC_ACTIVITIES,
    PUT_CRC_ACTIVITIES_SUCCESS,
    PUT_CRC_ACTIVITIES_ERROR,
    GET_CRC_ACTIVITY_DETAIL,
    GET_CRC_ACTIVITY_DETAIL_SUCCESS,
    GET_CRC_ACTIVITY_DETAIL_ERROR
} from "../actions";


export const getUserDetails = () => ({
    type: GET_USER_DETAILS
});
export const getUserDetailsSuccess = (payload) => ({
    type: GET_USER_DETAILS_SUCCESS,
    payload
});
export const getUserDetailsError = (payload) => ({
    type: GET_USER_DETAILS_ERROR,
    payload
});

export const updateProfile = (payload) => ({
    type: UPDATE_PROFILE,
    payload
});
export const updateProfileSuccess = () => ({
    type: UPDATE_PROFILE_SUCCESS
});
export const updateProfileError = () => ({
    type: UPDATE_PROFILE_ERROR
});

export const getCRCActivities = (payload) => ({
    type: GET_CRC_ACTIVITIES,
    payload
});
export const getCRCActivitiesSuccess = (payload) => ({
    type: GET_CRC_ACTIVITIES_SUCCESS,
    payload
});
export const getCRCActivitiesError = (payload) => ({
    type: GET_CRC_ACTIVITIES_ERROR,
    payload
});

export const addCRCActivities = (payload) => ({
    type: POST_CRC_ACTIVITIES,
    payload
});
export const addCRCActivitiesSuccess = (payload) => ({
    type: POST_CRC_ACTIVITIES_SUCCESS,
    payload
});
export const addCRCActivitiesError = (payload) => ({
    type: POST_CRC_ACTIVITIES_ERROR,
    payload
});

export const updateCRCActivities = (payload) => ({
    type: PUT_CRC_ACTIVITIES,
    payload
});
export const updateCRCActivitiesSuccess = (payload) => ({
    type: PUT_CRC_ACTIVITIES_SUCCESS,
    payload
});
export const updateCRCActivitiesError = (payload) => ({
    type: PUT_CRC_ACTIVITIES_ERROR,
    payload
});

export const getCRCActivityDetail = (payload) => ({
    type: GET_CRC_ACTIVITY_DETAIL,
    payload
});
export const getCRCActivityDetailSuccess = (payload) => ({
    type: GET_CRC_ACTIVITY_DETAIL_SUCCESS,
    payload
});
export const getCRCActivityDetailError = (payload) => ({
    type: GET_CRC_ACTIVITY_DETAIL_ERROR,
    payload
});
