import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label
} from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useAlert } from 'react-alert';

const DPO = () => {
  
  const alert = useAlert();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [data, setData] = useState({
    email: "",
    userId: "",
    password: "",
    role: { label: "DPO", value: "DPO" },
    name: ""
  });

  const handleChange = e => {
    const name = e.target.name;
    const val = e.target.value;
    setData({ ...data, [name]: val });
  };

  const [dpo, setDpo] = useState([]);

  const history = useHistory();
  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "DPO", link: "#" }
  ];

  const handleSubmit = async () => {
    if (data.name && data.email && data.userId && data.password) {
      try {
        await axios.post("http://localhost:8000/users/signupDPO", {...data, role: data.role.value });
        alert.success('New DPO account Created.')
        toggleModal();
      } catch (error) {
        alert.error(`Error, ${error.message}`)
      }
    } else {
      alert.error(`Insufficent Data`)
    }
  };

  

  async function getAllDPOs() {
    try {
      const { data } = await axios.get("http://localhost:8000/dpo/all");
      setDpo(data.data)
      return data.data
    } catch (error) {
      return error
    }
  }
  useEffect(() => {
    try {
      getAllDPOs();
    } catch (error) {
      alert.error(`Error, ${error.message}`)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="DPO's"
            breadcrumbItems={breadcrumbItems}
            showBtn={true}
            handleClick={() => {
              history.push(`/dashboard/`);
            }}
          />

          <Row>
            <Col md={12}>
              <Button className="mb-3" color="primary" onClick={toggleModal}>
                Create New DPO
              </Button>

              <Modal isOpen={isModalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>
                  Create a new DPO Account
                </ModalHeader>
                <ModalBody>
                  <AvForm className="form-horizontal">
                    <FormGroup className="auth-form-group-custom mb-4">
                      <i className="ri-lock-2-line auti-custom-input-icon" />
                      <Label htmlFor="userName">Name</Label>
                      <AvField
                        name="name"
                        value={data.name}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="username"
                        placeholder="Enter Name"
                      />
                    </FormGroup>

                    <FormGroup className="auth-form-group-custom mb-4">
                      <i className="ri-user-2-line auti-custom-input-icon" />
                      <Label htmlFor="email">Username</Label>
                      <AvField
                        name="userId"
                        value={data.userId}
                        onChange={handleChange}
                        validate={{ required: true }}
                        type="text"
                        className="form-control"
                        id="userId"
                        placeholder="Enter username"
                      />
                    </FormGroup>

                    <FormGroup className="auth-form-group-custom mb-4">
                      <i className="ri-user-2-line auti-custom-input-icon" />
                      <Label htmlFor="email">Email</Label>
                      <AvField
                        name="email"
                        value={data.email}
                        onChange={handleChange}
                        validate={{ email: true, required: true }}
                        type="email"
                        autoComplete="off"
                        className="form-control"
                        id="email"
                        placeholder="Enter email"
                      />
                    </FormGroup>

                    <FormGroup className="auth-form-group-custom mb-4">
                      <i className="ri-lock-2-line auti-custom-input-icon" />
                      <Label htmlFor="userpassword">Password</Label>
                      <AvField
                        name="password"
                        value={data.password}
                        onChange={handleChange}
                        type="password"
                        autoComplete="new-password"
                        className="form-control"
                        id="userpassword"
                        placeholder="Enter password"
                      />
                    </FormGroup>
                  </AvForm>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    onClick={toggleModal}
                    color="light"
                    className="waves-effect"
                  >
                    Close
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={() => handleSubmit()}
                  >
                    Create
                  </Button>
                </ModalFooter>
              </Modal>

              <Card style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                <CardBody>
                  <Row>
                    <table className="table table-hover">
                      <thead>
                        <tr className="table-primary">
                          <th>#</th>
                          <th>DPO Name</th>
                          <th>DPO Username</th>
                          <th>DPO Email</th>
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dpo &&
                          dpo.length > 0 &&
                          dpo.map((item, i) =>
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.userId}</td>
                              <td>{item.email}</td>
                              <td>Edit</td>
                              <td>Delete</td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DPO;
