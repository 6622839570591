import React from "react";
import { DashboardRoutes } from "./Constants/routes";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";

import { connect } from "react-redux";
import CRCProfile from "./Components/CRC/Components/CRCProfile";
import TaskDetails from "./Components/CRC/Components/TaskDetails";

const Dashboard = ({ role, user }) => {
  const match = useRouteMatch();
  return (
    <div>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/`} />
        {DashboardRoutes.map(({ path, Component, exact, id }) =>
          <Route
            path={`${match.url}${path}`}
            exact={exact}
            component={Component}
            key={id}
          />
        )}

        <Route path="/dashboard/crc/:userId" exact={true} component={CRCProfile} />
        <Route path="/dashboard/crc/:userId/add" exact={true} component={TaskDetails} />
        <Route path="/dashboard/crc/:userId/view/:taskId" exact={true} component={TaskDetails} />

        {user.onboard === 0
          ? <Redirect to="/profile" />
          : <Redirect to="/dashboard/home" />}
      </Switch>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  role: auth.role,
  user: auth.user
});

export default connect(mapStateToProps, null)(Dashboard);
