import { getLocal, setLocal } from "../../helpers/localstorage";
import {
    GET_USER_DETAILS,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_ERROR,
    UPDATE_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_ERROR,
    GET_CRC_ACTIVITIES,
    GET_CRC_ACTIVITIES_SUCCESS,
    GET_CRC_ACTIVITIES_ERROR,
    POST_CRC_ACTIVITIES,
    POST_CRC_ACTIVITIES_SUCCESS,
    POST_CRC_ACTIVITIES_ERROR,
    PUT_CRC_ACTIVITIES,
    PUT_CRC_ACTIVITIES_SUCCESS,
    PUT_CRC_ACTIVITIES_ERROR,
    GET_CRC_ACTIVITY_DETAIL,
    GET_CRC_ACTIVITY_DETAIL_SUCCESS,
    GET_CRC_ACTIVITY_DETAIL_ERROR
} from "../actions";

const initialState = {
    userDetails: {},
    crcActivities: [],
    activityDetail: {},
    error: false,
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {

        case GET_USER_DETAILS:
            return {
                ...state,
                error: false,
                loading: true,
                userDetails: {}
            };
        case GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                userDetails: action.payload.data
            };
        case GET_USER_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                userDetails: {}
            };

        case UPDATE_PROFILE:
            return {
                ...state,
                error: false,
                loading: true,
                user: {}
            };

        case UPDATE_PROFILE_SUCCESS:
            const oldUser = JSON.parse(getLocal("authUser"));
            setLocal("authUser", JSON.stringify({ ...oldUser, onboard: 1 }))
            return {
                ...state,
                loading: false,
                error: false
            };
        case UPDATE_PROFILE_ERROR:
            return {
                ...state,
                user: {},
                loading: false,
                error: true
            };

        case GET_CRC_ACTIVITIES:
            return {
                ...state,
                error: false,
                loading: true,
                crcActivities: []
            };
        case GET_CRC_ACTIVITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                crcActivities: action.payload.data
            };
        case GET_CRC_ACTIVITIES_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                crcActivities: []
            };

        case POST_CRC_ACTIVITIES:
            return {
                ...state,
                error: false,
                loading: true,
                crcActivities: []
            };
        case POST_CRC_ACTIVITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                crcActivities: action.payload.data
            };
        case POST_CRC_ACTIVITIES_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                crcActivities: action.payload.data
            };

        case PUT_CRC_ACTIVITIES:
            return {
                ...state,
                error: false,
                loading: true,
                // activityDetail: []
            };
        case PUT_CRC_ACTIVITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                // activityDetail: action.payload.data
            };
        case PUT_CRC_ACTIVITIES_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                // activityDetail: action.payload.data
            };

        case GET_CRC_ACTIVITY_DETAIL:
            return {
                ...state,
                error: false,
                loading: true,
                activityDetail: {}
            };
        case GET_CRC_ACTIVITY_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                activityDetail: action.payload.data
            };
        case GET_CRC_ACTIVITY_DETAIL_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                activityDetail: action.payload.data
            };

        default:
            return { ...state };
    }
}